body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: black; /* 画面全体の背景を黒にする */
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 画面の高さに合わせる */
    width: 100vw; /* 画面の幅に合わせる */
    overflow: hidden; /* はみ出した部分を隠す */
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* アスペクト比を維持しつつ、コンテナ内に収まるように表示 */
  }
  